<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <button class="btn btn-success m-2" type="button" @click="exportTable" v-if="$can('partner_voucher_settlement_export')">Export</button>
          <a class="btn btn-info m-2" href="voucher-settlement/report" rel="noreferrer noopener" v-if="$can('partner_voucher_settlement_report')">
            Lihat Grafik
          </a>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm="12">
        <div>
          <b-form-group
            class="mb-3"
            label="Search :"
            label-for="search"
            description="Searchable : Name, Code, Phone Number, Partner Invoice Number"
          >
              <b-input-group
              >
                <b-form-input
                  id="search"
                  type="search"
                  v-model="filterText"
                  placeholder="Name, Code, Phone Number, Partner Invoice Number ..."
                  @keyup.enter="doFilter"
                >
                </b-form-input>
                <b-input-group-append
                >
                  <b-button
                    variant="secondary" @click="resetFilter"
                    type="button"
                  >
                    Reset
                  </b-button>
                </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Transaction Date"
            label-for="daterange"
          >
            <b-input-group
            >
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker id="daterange" :start="startDate" :end="endDate" @picker="doDateFilter"></range-picker>
              <b-input-group-append>
                <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Transaction Type"
            label-for="sp"
          >
            <b-form-select
              id="sp"
              v-model="filterType"
              :options="optionsType"
              @change="doFilterSelect('transaction_type', filterType)">
            </b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Prefix"
            label-for="sp"
          >
            <b-form-select
              id="sp"
              v-model="filterPrefix"
              :options="optionsPrefix"
              @change="doFilterSelect('prefix', filterPrefix)">
            </b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Partner"
            label-for="sp"
          >
            <b-form-select
              id="sp"
              v-model="filterPartner"
              :options="optionsPartner"
              @change="doFilterSelect('partner_id', filterPartner)">
            </b-form-select>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable ref="vuetable"
      :api-mode="false"
			:fields="fields"
      :per-page="limit"
			:data-manager="dataManager">
      <template slot="transaction_date-slot" slot-scope="prop">
        <span>{{ prop.rowData.transaction_date || '-' }}</span>
      </template>
      <template slot="partner_invoice_number-slot" slot-scope="prop">
        <span>{{ prop.rowData.partner_invoice_number || '-' }}</span>
      </template>
      <template slot="transaction_type-slot" slot-scope="prop">
        <span>{{ prop.rowData.transaction_type || '-' }}</span>
      </template>
      <template slot="prefix-slot" slot-scope="prop">
        <span>{{ prop.rowData.prefix || '-' }}</span>
      </template>
      <template slot="partner-slot" slot-scope="prop">
        <span>{{ prop.rowData.partner_name || '-' }}</span>
      </template>
      <template slot="code-slot" slot-scope="prop">
        <span>{{ prop.rowData.code || '-' }}</span>
      </template>
      <template slot="name-slot" slot-scope="prop">
        <span>{{ prop.rowData.name || '-' }}</span>
      </template>
      <template slot="phone-slot" slot-scope="prop">
        <span>{{ prop.rowData.phone_number || '-' }}</span>
      </template>
      <template slot="amount-slot" slot-scope="prop">
        <span>{{ prop.rowData.amount || '-' }}</span>
      </template>
      <template slot="commission-slot" slot-scope="prop">
        <span>{{ prop.rowData.commision || '-' }}</span>
      </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <div class="wrapper-pagination-custom">
        <div class="pagination-custom-info">
          <p>{{ this.paginationInfo }}</p>
        </div>
        <div class="pagination-custom-button">
          <button :disabled="currentPage === 1" @click="doMovePage('first')">
            <i v-if="currentPage === 1" class="fa fa-angle-double-left disabled"></i>
            <i v-else class="fa fa-angle-double-left"></i>
          </button>
          <button :disabled="currentPage === 1" @click="doMovePage('prev')">
            <i v-if="currentPage === 1" class="fa fa-angle-left disabled"></i>
            <i v-else class="fa fa-angle-left"></i>
          </button>
          <div>{{ this.currentPage }}</div>
          <button :disabled="data.length < 10" @click="doMovePage('next')">
            <i v-if="data.length < 10" class="fa fa-angle-right disabled"></i>
            <i v-else class="fa fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import axios from 'axios'
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import moment from 'moment'

  let startDate = '';
  let endDate = '';
  Vue.use(Vuetable)

  export default {
    components: {
      Vuetable
    },
    prop: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    data() {
      return {
        page: 1,
        currentPage: 1,
        limit: 10,
        data: [],
        isLoading: false,
        isLoadingTable: false,
        startDate: '',
        endDate: '',
        filterText: '',
        filterType: '',
        filterPrefix: '',
        filterPartner: '',
        optionsPartner: [
          { value: '', text: 'Choose Partner' }
        ],
        optionsPrefix: [
          { value: '', text: 'Choose Prefix' }
        ],
        optionsType: [
          { value: '', text: 'Choose Transaction Type' }
        ],
        dateDetail: {
          start_from: '',
          end_from: ''
        },
        errors: {
          code: '',
          message: '',
          status: ''
        },
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `voucher/partner/settlement/list`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sortOrder: [
          {
            field: 'transaction_date',
            sortField: 'transaction_date',
            direction: 'desc'
          }
        ],
        sort: 'transaction_date|desc',
        moreParams: {},
        fields: [
          {
            name: 'transaction_date-slot',
            sortField: 'transaction_date',
            title: 'Transaction Date'
          },
          {
            name: 'partner_invoice_number-slot',
            sortField: 'partner_invoice_number',
            title: 'Partner Invoice Number'
          },
          {
            name: 'transaction_type-slot',
            sortField: 'transaction_type',
            title: 'Transaction Type'
          },
          {
            name: 'prefix-slot',
            sortField: 'prefix',
            title: 'Prefix'
          },
          {
            name: 'partner-slot',
            sortField: 'partner',
            title: 'Partner'
          },
          {
            name: 'code-slot',
            sortField: 'code',
            title: 'Code'
          },
          {
            name: 'name-slot',
            sortField: 'name',
            title: 'Name'
          },
          {
            name: 'phone-slot',
            sortField: 'phone',
            title: 'Phone Number'
          },
          {
            name: 'amount-slot',
            sortField: 'amount',
            title: 'Amount'
          },
          {
            name: 'commission-slot',
            sortField: 'commission',
            title: 'commission Number'
          }
        ]
      }
    },
    watch: {
      // eslint-disable-next-line no-unused-vars
      data(newVal, oldVal) {
        this.$refs.vuetable.refresh();
      }
    },
    created() {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
      this.fetchs()
      this.fetchPrefix()
      this.fetchPartners()
      this.fetchType()
    },
    methods: {
      fetchs() {
        const newParams = this.stringifyParams(this.moreParams, '&')

        this.$http.get(`voucher/partner/settlement/list?sort=${encodeURIComponent(this.sort)}&page=${this.page}&per_page=10${newParams}`)
          .then((response) => {
            const startIndex = (response.data.current_page - 1) * this.limit;
            const endIndex = startIndex + this.limit;

            this.data = response.data.data;
            this.currentPage = response.data.current_page;
            this.paginationInfo = response.data.data.length > 0 ? `Displaying ${startIndex + 1} to ${endIndex}` : 'No relevant data';

            if (this.isLoadingTable) this.isLoadingTable = false;
          })
          .catch((err) => {
            this.handleLoadError(err)
          })
      },
      fetchPartners() {
        this.$http.get(`partner-list`)
          .then((result) => {
            this.isLoading = false
            const newPartner = result.data.map((item) => { return { value: item.id, text: item.name } })
            this.isLoading = false
            this.optionsPartner = [
              ...this.optionsPartner,
              ...newPartner
            ]
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
            }
          })
      },
      fetchPrefix() {
        this.$http.get(`voucher/partner/prefix`)
          .then((result) => {
            this.isLoading = false
            const newPrefix = result.data.data.map((item) => { return { value: item.prefix, text: item.prefix } })
            this.isLoading = false
            this.optionsPrefix = [
              ...this.optionsPrefix,
              ...newPrefix
            ]
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
            }
          })
      },
      fetchType() {
        this.$http.get(`voucher/partner/settlement/transaction-type`)
          .then((result) => {
            this.isLoading = false
            const newType = result.data.data.map((item) => { return { value: item.value, text: item.name } })
            this.isLoading = false
            this.optionsType = [
              ...this.optionsType,
              ...newType
            ]
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
            }
          })
      },
      async exportTable() {
        const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => (key !== 'start_from') && (key != 'end_to')))
        const moreParams = await this.stringifyParams(newParams, '?')
        const currentDate = moment(this.$setDate);
        this.isLoading = true

        if (this.startDate != '' && this.endDate != '') {
          startDate = this.startDate;
          endDate = this.endDate;
        }else{
          endDate = this.$setDate.format('YYYY-MM-DD');
          startDate = currentDate.subtract(6, 'days').format('YYYY-MM-DD');
        }

        this.$http.get(`partner-voucher-settlement/${startDate}/${endDate}${moreParams}`)
          .then((result) => {
            this.isLoading = false
            const  exportPath = result.data.meta.data;
            window.location.href = exportPath;
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
            }
        })
      },
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      doFilter() {
        this.$events.$emit('filter-set', this.filterText)
      },
      onFilterSet(filterText) {
        const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'filter'))
        this.moreParams = {
          ...newParams,
          'filter': filterText
        }
        this.fetchs()
      },
      doDateFilter(value){
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.$events.fire('date-set', [this.startDate, this.endDate])
      },
      onDateSet() {
        const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => (key !== 'start_from') && (key != 'end_to')))
        this.start_from = this.startDate
        this.end_to = this.endDate
        this.page = 1;
        this.moreParams = {
          ...newParams,
          'start_from': this.start_from,
          'end_to': this.end_to
        }
        this.fetchs()
      },
      resetFilter() {
        this.filterText = ''  // clear the text in text input
        this.$events.$emit('filter-reset')
      },
      onFilterReset() {
        this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'filter'))
        this.page = 1
        this.fetchs()
      },
      resetDateFilter() {
        this.startDate = ''
        this.endDate = ''
        this.$events.$emit('date-filter-reset')
      },
      onDateFilterReset() {
        this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => (key !== 'start_from') && (key != 'end_to')))
        this.page = 1
        this.fetchs()
      },
      doFilterSelect(type, value) {
        this.$events.$emit('select-set', type, value)
      },
      onFilterSelectSet(type, value) {
        const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== type))
        this.moreParams = value ? {
          ...newParams,
          [type]: value
        } : newParams;
        this.fetchs()
      },
      stringifyParams(params, connector) {
        const keys = Object.keys(params)

        if (keys.length === 0) {
          return ''
        } else if (keys.length === 1) {
          const key = keys[0]
          return `${connector}${key}=${params[key]}`
        } else {
          return `${connector}${keys.map((key) => `${key}=${params[key]}`).join('&')}`
        }
      },
      doMovePage(value) {
        this.$events.$emit('movePage', value)
      },
      onMovePage (eventData) {
        if (eventData === 'first') {
          this.page = 1;
          this.fetchs();
        } else if (eventData === 'prev') {
          this.page--;
          this.fetchs();
        } else {
          this.page++;
          this.fetchs();
        }
      },
      dataManager(sortOrder, pagination) {
        let local = this.data;

        if (sortOrder.length > 0) {
          const newSort = `${sortOrder[0].sortField}|${sortOrder[0].direction}`;

          if (this.sort !== newSort) {
            this.sort = newSort;
            this.fetchs();
          }
        }

        pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.limit
        );

        let from = pagination.from - 1;
        let to = from + this.limit;

        return {
          pagination: pagination,
          data: local.length > 0 ? _.slice(local, from, to) : []
        };
      }
    },
    mounted() {
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('filter-reset', () => this.onFilterReset())
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
      this.$events.$on('select-set', (type, eventData) => this.onFilterSelectSet(type, eventData))
      this.$events.$on('movePage', eventData => this.onMovePage(eventData))
    },
  }
</script>
<style>

.badge--code {
  font-size: 10pt !important;
}
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

/* Pagination Custom */
.wrapper-pagination-custom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafb;
}

.pagination-custom-button {
  display: flex;
}

.pagination-custom-button button {
  width: 28px;
  height: 47px;
  padding: 13px 16px;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: solid rgba(0,0,0,0.3);
}

.pagination-custom-button button:first-child {
  padding: 13px 16px 13px 30px;
  border-radius: 4px 0 0 4px;
  border-width: 1px 0 1px 1px
}

.pagination-custom-button button:nth-child(2),
.pagination-custom-button button:last-child {
  font-size: 1.3em;
  border-width: 1px;
}

.pagination-custom-button button:last-child {
  border-radius: 0 4px 4px 0;
}

.pagination-custom-button button i.disabled {
  opacity: 0.6;
}

.pagination-custom-button div {
  width: 28px;
  height: 47px;
  font-size: 1em;
  font-weight: 500;
  background: #FFFFFF;
  border: solid rgba(0,0,0,0.3);
  border-width: 1px 0 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
